class Agenda {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
   constructor({ el }) {
    this.root = el;
    
    this.filters = this.root.querySelector('.agenda-filters');
    this.filterTriggers = this.root.querySelectorAll('.js-toggle-filters');
    this.filterCheckboxes = this.root.querySelectorAll('input[type="checkbox"]');
    this.filterSubmit = this.root.querySelector('[type="submit"]');
    this.filterCheckedCheckboxes = this.root.querySelectorAll('input[type="checkbox"]:checked');
    // this.activeFilters = this.root.querySelectorAll('.js-agenda-remove-item');
    this.removeAllFilters = this.root.querySelector('.js-agenda-remove-all');
    this.agendaFilterForm = this.root.querySelector('.js-agenda-filter-form')

    console.log(this.filterCheckedCheckboxes);

    this.initialCheckboxes = [];
    this.checkedCheckboxes = [];

    for (let i = 0; i < this.filterCheckboxes.length; i++) {
      this.filterCheckboxes[i].addEventListener('change', this.onCheckboxChange.bind(this));      
    }

    for (let i = 0; i < this.filterCheckedCheckboxes.length; i++) {
      this.initialCheckboxes.push(this.filterCheckedCheckboxes[i].value);
      this.checkedCheckboxes.push(this.filterCheckedCheckboxes[i].value);
    }

    this._toggleFilters = this.toggleFilters.bind(this);
    // this._onRemoveActiveFilter = this.onRemoveActiveFilter.bind(this);
    // this._onRemoveAllFilter = this.onRemoveAllFilter.bind(this);
    this._onKeyDown = this.onKeyDown.bind(this);

    for (let i = 0; i < this.filterTriggers.length; i++) {
      this.filterTriggers[i].addEventListener('click', this._toggleFilters);    
    }

    // for (let i = 0; i < this.activeFilters.length; i++) {
    //   this.activeFilters[i].addEventListener('click', this._onRemoveActiveFilter);
    // }

    // this.removeAllFilters.addEventListener('click', this._onRemoveAllFilter);

    this.checkEquality();
    window.addEventListener('keydown', this._onKeyDown);
  }

  // onRemoveAllFilter() {
  //   for (let i = 0; i < this.activeFilters.length; i++) {
  //     const forValue = this.activeFilters[i].getAttribute('for');
  //     const checkbox = this.root.querySelector(`#${forValue}`);
  //     checkbox.checked = false;
  //   }

  //   this.agendaFilterForm.submit();
  // }

  // onRemoveActiveFilter() {
  //   this.agendaFilterForm.submit();
  // }

  onCheckboxChange(e) {
    const { currentTarget } = e;
    const value = currentTarget.value;

    if ((this.checkedCheckboxes.indexOf(value) > -1)) {
      this.checkedCheckboxes.splice(this.checkedCheckboxes.indexOf(value), 1);
    } else {
      this.checkedCheckboxes.push(value);
    }

    this.checkEquality();
  }

  checkEquality() {
    this.filterSubmit.disabled = this.arraysEqual(this.initialCheckboxes, this.checkedCheckboxes);
  }

  arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  toggleFilters() {
    this.filters.classList.toggle('is-open');
  }

  closeFilters() {
    this.filters.classList.remove('is-open');
  }

  onKeyDown(e) {
    if (e.keyCode === 27) {
      this.closeFilters();
    }
  }
}

export default Agenda;

