// RemoveFilters Class
class RemoveFilters {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.formId = this.root.getAttribute('data-controls-form');
    this.controlledForm = document.getElementById(this.root.getAttribute('data-controls-form'));
    this.allButton = this.root.querySelector('.js-remove-filters-button-all');
    this.buttons = this.root.querySelectorAll('.js-remove-filters-button');

    this._onButtonClick = this.onButtonClick.bind(this);
    this._onAllButtonClick = this.onAllButtonClick.bind(this);
    
    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].addEventListener('click', this._onButtonClick)
    }

    if (this.allButton) {
      this.allButton.addEventListener('click', this._onAllButtonClick)
    }
  }

  onButtonClick(e) {
    e.preventDefault();
    const forAttr = e.currentTarget.getAttribute('for');
    const input = document.getElementById(forAttr);

    if (input) {
      input.checked = false;
      this.controlledForm.submit();
    }
  }

  onAllButtonClick(e) {
    e.preventDefault();

    for (let i = 0; i < this.buttons.length; i++) {
      const forValue = this.buttons[i].getAttribute('for');
      const input = document.getElementById(forValue);
      input.checked = false;
    }

    this.controlledForm.submit();
  }
}

export default RemoveFilters;
