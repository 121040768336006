// OrderFilter Class
class OrderFilter {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.inputs = this.root.querySelectorAll('input');

    for (let i = 0; i < this.inputs.length; i++) {
      this.inputs[i].addEventListener('change', this.inputChanged.bind(this));
    }
  }

  inputChanged(e) {
    e.currentTarget.form.submit();
  }
}

export default OrderFilter;
