class ScrollTitle {

  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.wrapper = this.root.querySelector('.partners-scroller__wrapper');
    this.repeat = this.root.querySelector('.partners-scroller__repeat');
    this.clone = this.repeat.cloneNode(true);
    this.images = this.root.querySelectorAll('img');
    this.loadedImages = 0;
    this.repeatWidth = 0;
    this.translateX = 0;

    this._resize = this.resize.bind(this);
    this._RAF = this.RAF.bind(this);

    for (let i = 0; i < this.images.length; i++) {
      if (this.images[i].naturalWidth) {
        this.loadedImages += 1;
        this.resize();
      } else {
        this.images[i].addEventListener('load', () => {
          this.resize();
          this.loadedImages += 1;
        });
      }
    }

    this.resize();
    this.Raf = requestAnimationFrame(this._RAF);

    window.addEventListener('resize', this._resize);
  }

  RAF() {
    this.translateX += 1;

    if (this.translateX >= this.repeatWidth) {
      this.translateX = 0;
    }

    this.wrapper.style.transform = `translateX(-${this.translateX}px)`;

    this.Raf = requestAnimationFrame(this._RAF);
  }

  resize() {
    if (this.loadedImages === this.images.length - 1) {
      this.repeatWidth = this.repeat.getBoundingClientRect().width;

      const delta = Math.ceil(window.innerWidth / this.repeatWidth) + 1;
      
      this.wrapper.innerHTML = '';
      for (let i = 0; i < delta; i++) {
        this.wrapper.appendChild(this.clone.cloneNode(true));
      }
    }
  }
}

export default ScrollTitle;
