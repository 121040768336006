// SiteHeader Class
class SiteHeader {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.menuTriggers = document.querySelectorAll('.js-site-header-trigger');
    this.searchTriggers = document.querySelectorAll('.js-site-search-trigger');
    this.searchInput = document.querySelector('.site-search input');

    this._onMenuTriggerClick = this.onMenuTriggerClick.bind(this);
    this._onSearchTriggerClick = this.onSearchTriggerClick.bind(this);

    for (let i = 0; i < this.menuTriggers.length; i++) {
      this.menuTriggers[i].addEventListener('click', this._onMenuTriggerClick);
    }

    for (let i = 0; i < this.searchTriggers.length; i++) {
      this.searchTriggers[i].addEventListener('click', this._onSearchTriggerClick);
    }

    window.addEventListener('keydown', (e) => {
      if (e.keyCode === 27) {
        document.body.classList.remove('menu-is-open');
        document.body.classList.remove('search-is-open');
      }
    });
  }

  onMenuTriggerClick() {
    document.body.classList.toggle('menu-is-open');
  }

  onSearchTriggerClick() {
    document.body.classList.toggle('search-is-open');
    setTimeout(() => {
      this.searchInput.focus();
    }, 20);
  }
}

export default SiteHeader;
