import { tns } from 'tiny-slider/src/tiny-slider';

// ImageSlider Class
class ImageSlider {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.slider = tns({
      container: this.root,
      items: 1,
      nav: false,
      gutter: 20,
      slideBy: 'page',
      loop: false,
      autoHeight: true,
      controls: true,
      mouseDrag: true,
      controlsText: ['Précédent', 'Suivant'],
      controlsPosition: 'top'
    });
  }
}

export default ImageSlider;
