// StickySidebar Class
class StickySidebar {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.startEl = document.querySelector('.js-sticky-sidebar-start');
    this.endEl = document.querySelector('.js-sticky-sidebar-end');

    console.log(this.root.parentElement);

    this._onScroll = this.onScroll.bind(this);
    this._onResize = this.onScroll.bind(this);

    document.fonts.ready.then(() => {
      this.onResize();
      this.onScroll();
    });

    document.addEventListener('scroll', this._onScroll);
    document.addEventListener('resize', this._onResize);
  }

  onScroll() {
    const isIn = this.start - window.pageYOffset < 0;
    const isOver = this.end - window.pageYOffset < 0;
    
    if (isIn && !isOver) {
      this.root.classList.add('is-sticky');
    } else if (isIn && isOver) {
      this.root.classList.remove('is-sticky');
      this.root.classList.add('at-bottom');
    } else {
      this.root.classList.remove('is-sticky');
      this.root.classList.remove('at-bottom');
    }
  }

  onResize() {
    this.root.classList.remove('is-sticky');
    this.root.classList.remove('at-bottom');
    this.start = this.startEl.getBoundingClientRect().top + window.pageYOffset;
    this.end = this.endEl.getBoundingClientRect().top + window.pageYOffset - window.innerHeight;
    
    if (window.innerWidth > 1159) {
      this.root.parentElement.style.minHeight = `${this.root.offsetHeight}px`;
    } else {
      this.root.parentElement.style.minHeight = 'auto';
    }
  }
}

export default StickySidebar;
