// PageHeader Class
class PageHeader {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.filterItems = this.root.querySelectorAll('.page-header__filters-options-item');
    this.filterButtons = this.root.querySelectorAll('.page-header__filters-options-item-button');
    this.filterDropdownItems = this.root.querySelectorAll('.page-header__filters-options-item-dropdown');

    // Bindings
    for (let i = 0; i < this.filterButtons.length; i++) {
      this.filterButtons[i].addEventListener('click', this.onFilterButtonClick.bind(this, i));
    }

    for (let i = 0; i < this.filterDropdownItems.length; i++) {
      this.filterDropdownItems[i].addEventListener('click', this.onFilterOptionClick.bind(this));
    }
  }

  onFilterButtonClick(j) {
    const wasOpen = this.filterItems[j].classList.contains('is-open');
    
    for (let i = 0; i < this.filterItems.length; i++) {
      this.filterItems[i].classList.remove('is-open');
    }

    if (!wasOpen) {
      this.filterItems[j].classList.add('is-open');
    }
  }

  onFilterOptionClick(e) {
  }
}

export default PageHeader;
