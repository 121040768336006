// DropdownFilter Class
class Accordion {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.buttons = this.root.querySelectorAll('.js-accordion-button');

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].addEventListener('click', this.onFilterButtonClick.bind(this));
    }
  }

  onFilterButtonClick(e) {
    const wasOpen = e.currentTarget.classList.contains('is-open');
    // const buttons = this.root.querySelectorAll('.js-accordion-button.is-open');
    // for (let i = 0; i < buttons.length; i++) {
    //   buttons[i].classList.remove('is-open');
    // }

    if (!wasOpen) {
      e.currentTarget.classList.add('is-open');
    } else {
      e.currentTarget.classList.remove('is-open');
    }

    window.stickySidebar.onResize();
  }
}

export default Accordion;
