// DropdownFilter Class
class DropdownFilter {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.filterButton = this.root.querySelector('.js-dropdown-filter-button');
    this.inputs = this.root.querySelectorAll('input');

    this._inputClick = this.inputClick.bind(this);

    for (let i = 0; i < this.inputs.length; i++) {
      this.inputs[i].addEventListener('change', this._inputClick);
    }

    this.filterButton.addEventListener('click', this.onFilterButtonClick.bind(this));
  }

  inputClick(e) {
    e.currentTarget.form.submit();
  }

  onFilterButtonClick() {
    const wasOpen = this.root.classList.contains('is-open');
    const dropdowns = document.querySelectorAll('.js-dropdown-filter.is-open');

    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].classList.remove('is-open');
    }

    if (!wasOpen) {
      this.root.classList.add('is-open');
    }
  }
}

export default DropdownFilter;
