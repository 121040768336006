// https://www.npmjs.com/package/custom-select
import customSelect from 'custom-select';

// CustomSelect Class
class CustomSelect {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.select = this.root.querySelector('select');

    customSelect(this.select);
  }
}

export default CustomSelect;
