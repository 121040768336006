import CustomSelect from './components/custom-select';
import PageHeader from './components/page-header';
import StickySidebar from './components/sticky-sidebar';
import DropdownFilter from './components/dropdown-filter';
import OrderFilter from './components/order-filter';
import Accordion from './components/accordion';
import SiteHeader from './components/site-header';
import PartnersScroller from './components/partners-scroller';
import RemoveFilters from './components/remove-filters';
import Tabs from './components/tabs';
import ImageSlider from './components/image-slider';

import Agenda from './pages/agenda';

// Define views
const VIEWS = {
  'agenda': Agenda
};

const COMPONENTS = {
  '.js-dropdown-filter': DropdownFilter,
  '.js-order-filter': OrderFilter,
  '.js-custom-select': CustomSelect,
  '.js-sticky-sidebar': StickySidebar,
  '.js-page-header': PageHeader,
  '.js-accordion': Accordion,
  '.js-partners-scroller': PartnersScroller,
  '.js-remove-filters': RemoveFilters,
  '.js-tabs': Tabs,
  '.wp-block-gallery.has-nested-images': ImageSlider,
  '.blocks-gallery-grid': ImageSlider,
};

// Manager Class
class Manager {

  /**
   * Constructor:
   * Manager constructor called in `main.js` file.
   *
   * @constructor
   */
  constructor() {
    // Root element
    this.root = document.body;
    this.main = document.querySelector('[data-template]');
    this.header = document.querySelector('.site-header');

    document.body.classList.add('is-loaded');

    this._handleFirstTab = this.handleFirstTab.bind(this);

    // Key down listener
    window.addEventListener('keydown', this._handleFirstTab);

    // Init
    this.initLayout();
    this.initComponents();
  }

  initComponents() {
    const components = Object.entries(COMPONENTS);
    this.components = [];

    for (let i = 0; i < components.length; i++) {
      const elements = document.querySelectorAll(components[i][0]);

      for (let j = 0; j < elements.length; j++) {
        const component = new components[i][1]({
          el: elements[j]
        });

        if (components[i][0] === '.js-sticky-sidebar') {
          window.stickySidebar = component;
        }

        this.components.push(component);
      }
    }
  }

  /**
   * Initialize layout classes like `Header` and `Footer`.
   */
  initLayout() {
    // Header
    if (this.header && typeof this.header !== 'undefined') {
      this.SiteHeader = new SiteHeader({
        el: this.header
      });
    }

    // // Footer
    // if (this.footer && typeof Footer !== 'undefined') {
    //   this.Footer = new Footer({
    //     el: this.footer
    //   });
    // }

    // Get slug
    const slug = this.main.getAttribute('data-template');

    // Check view's class
    if (VIEWS[slug]) {
      this.View = new VIEWS[slug]({
        el: this.main
      });
    }
  }

  /**
   * Handle a first tab press, enable outline or not
   *
   * @param {Object} e - Keydown Event
   */
  handleFirstTab(e) {
    if (e.keyCode === 9) {
      // Update DOM
      document.documentElement.classList.add('user-is-tabbing');

      // Remove Event
      window.removeEventListener('keydown', this._handleFirstTab);
    }
  }
}

export default Manager;
