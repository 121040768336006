// Tabs Class
class Tabs {
  /**
   * @constructor
   * @param {Object} options - Class Options
   */
  constructor({ el }) {
    this.root = el;
    this.buttons = this.root.querySelectorAll('.js-tabs-button');
    this.content = this.root.querySelectorAll('.js-tabs-content');

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].addEventListener('change', this.onFilterChanged.bind(this));
    }
  }

  onFilterChanged(e) {
    for (let i = 0; i < this.content.length; i++) {
      this.content[i].classList.remove('is-active');
    }

    document.getElementById(`agenda-list-${e.target.value}`).classList.add('is-active');
  }
}

export default Tabs;
